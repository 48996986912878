
























































































import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Global, Projects } from '@/store';
import moment from 'moment-timezone';

@Component({})
export default class IncidetnsTableView extends Vue {

    @Global.State('lang') lang;
    @Projects.State('list') projectsList;

    @Prop() alert_types;
    @Prop() event_types;

    tableData = [];
    filtered_table = [];
    loading = true;
    sort = null;
    utils = Utils;
    prop_utils = PropUtils;
    pagination = {
        rowsPerPage: 5,
        totalItems: 0
    };
    sortBy = 'time';
    descending = true;
    filters = {};
    time_column_options = new Map();
    
    mounted() {
        this.resetFilters();
        this.generateIncidentsTable();
    }

    resetFilters() {
        this.headers.slice(0, -1)
            .filter((header) => header.search_field)
            .forEach((header) => {
                if (header.search_field === 'parsed_time') {
                    this.filters[header.search_field] = {options: [{text: this.$t('Last Hour'), value: '1hours'}, {text: this.$t('Last Day'), value: '1days'}, {text: this.$t('Last Week'), value: '1weeks'}], selected: '', search_value: '', index: -1, multiple: false};
                }else {
                    this.filters[header.search_field] = {options: [], selected: [], search_value: '', index: -1, multiple: true};
                }
            });
    }

    @Watch('alert_types', {deep: true, immediate: true})
    @Watch('event_types', {deep: true, immediate: true})
    generateIncidentsTable(){
        const data = [];
        
        Object.values(this.alert_types).forEach((alerts_data) => {
            alerts_data['list'].forEach((alert) => data.push(alert));
        });

        Object.values(this.event_types).forEach((events_data) => {
            events_data['list'].forEach((event) => data.push(event));
        });

        this.tableData = data;
        this.applyFilters(data);

        const sorted_header = this.headers.find((header) => header.hasOwnProperty('sort_field') && header['sort_field'] === this.sortBy);
        this.sortTable(sorted_header);
        this.pagination.totalItems = this.filtered_table.length;
        this.loading = false;
    }

    openIncident(incident) {
        this.$emit('handleIncidentClicked', incident);
    }

    changeSort(header_data) {
        if (this.sortBy === header_data.sort_field) {
            this.descending = !this.descending;
        }else {
            this.sortBy = header_data.sort_field;
            this.descending = false;
        }
        this.sortTable(header_data);
    }

    clearInput(filter_field){
        filter_field.search_value = '';
    }

    sortTable(header_data) {
        this.loading = true;
        if (header_data.hasOwnProperty('sortable') && !header_data.sortable){
            this.loading = false;
            return;
        }

        const data = cloneDeep(this.filtered_table);

        if (this.descending) {
            if (this.sortBy === 'time'){
                data.sort((incident1, incident2) => incident2[this.sortBy] - incident1[this.sortBy]);
            }else {
                data.sort((incident1, incident2) => incident2[this.sortBy].toString().localeCompare(incident1[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
            }
        }else {
            if (this.sortBy === 'time'){
                data.sort((incident1, incident2) => incident1[this.sortBy] - incident2[this.sortBy]);
            }else {
                data.sort((incident1, incident2) => incident1[this.sortBy].toString().localeCompare(incident2[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
            }
        }
        if (!isEqual(this.filtered_table, data)) {
            this.filtered_table = data;
        }
        this.loading = false;
    }

    clearSelections() {
        this.resetFilters();
        this.filtered_table = cloneDeep(this.tableData);
        this.applyFilters(this.tableData);
    }

    filterColumn(field_name) {
        this.filters[field_name].search_value = '';
        if (this.filters[field_name].index === -1) {
            const max_index = Math.max(...Object.values(this.filters).map((filter_data) => filter_data['index']));
            this.filters[field_name].index = max_index + 1;
        }else if (!this.filters[field_name].selected || !this.filters[field_name].selected.length){
            this.rearrangeFiltersIndex(this.filters[field_name].index);
            this.filters[field_name].index = -1;
        }

        this.applyFilters(this.tableData);
    }

    applyFilters(data){
        const now = moment().utcOffset(0).valueOf();
        const before_hour = moment(now).subtract(1, 'hours').valueOf();
        const before_day = moment(now).subtract(1, 'days').valueOf();
        const before_week = moment(now).subtract(1, 'weeks').valueOf();

        let filtered_table = cloneDeep(data);
        const filters_with_selected = Object.keys(this.filters).filter((field_name) => this.filters[field_name].selected && this.filters[field_name].selected.length);
        const filters_without_selected = Object.keys(this.filters).filter((field_name) => !this.filters[field_name].selected || !this.filters[field_name].selected.length);
        filters_with_selected.sort((f1, f2) => this.filters[f1]['index'] - this.filters[f2]['index']);
        filters_with_selected.forEach((field_name, index) => {
            let selected_time = null;
            if (field_name === 'parsed_time') {
                selected_time = this.filters[field_name].selected.toLowerCase();
            }
            filtered_table = filtered_table.filter((row) => {
                if (selected_time){
                    return selected_time.includes('hour') && before_hour < row.time ||
                        selected_time.includes('day') && before_day < row.time ||
                        selected_time.includes('week') && before_week < row.time;
                }
                const value = row[field_name].toString();
                return this.filters[field_name].selected.includes(value);
            });
            if (index + 1 < filters_with_selected.length && filters_with_selected[index + 1] !== 'parsed_time') {
                const next_field_name = filters_with_selected[index + 1];
                this.filters[next_field_name].options = [...new Set(filtered_table.map((incident) => incident[next_field_name].toString()))];
                this.filters[next_field_name].selected = this.filters[next_field_name].selected.filter((selected_value) => this.filters[next_field_name].options.includes(selected_value));
            }
        });
        filters_without_selected.filter((field_name) => field_name !== 'parsed_time').forEach((field_name) => {
            this.filters[field_name].options = [...new Set(filtered_table.map((incident) => incident[field_name].toString()))];
        });
        this.filtered_table = filtered_table;
    }

    rearrangeFiltersIndex(deleted_index){
        Object.values(this.filters)
            .filter((filter_data) => filter_data['index'] > deleted_index)
            .map((filter_data) => {
                filter_data['index'] -= 1;
            });
    }

    get headers() {
        return [
            { text: 'Date & Time', sort_field: 'time', search_field: 'parsed_time', sortable: true, select: true },
            { text: 'Ticket ID', sort_field: 'id', search_field: 'id', sortable: true, select: true },
            { text: 'Incident Type', sort_field: 'parsed_type', search_field: 'parsed_type', sortable: true, select: true },
            { text: 'Description', sort_field: 'description', search_field: 'description', sortable: true, select: true },
            { text: 'Severity', sort_field: 'parsed_priority', search_field: 'parsed_priority', sortable: true, select: true },
            { text: 'Relative Time', sort_field: 'time', search_field: 'relative_time', sortable: true, select: true },
            { text: 'Actions', sort_field: '', sortable: false }
        ];
    }
}
